import createIconLibrary from '@/helpers/fontAwesomeHelper'
import dashboardConfig from '@/pages/Dashboard/dashboardConfigs/dashboard'

createIconLibrary()

export const sidebarConfig = [
    {
        key: 'posts-container',
        label: 'Posts',
        icon: 'edit',
        children: [
            {
                key: 'posts',
                label: 'Posts',
                icon: 'edit',
                route: '/t/posts',
            },
            {
                key: 'post-categories',
                label: 'Categories',
                icon: 'box-open',
                route: '/t/post-categories',
            },
        ],
    },
    {
        key: 'archive-container',
        label: 'Archive',
        icon: 'archive',
        children: [
            {
                key: 'archive',
                label: 'Archive',
                icon: 'archive',
                route: '/t/archive',
            },
            {
                key: 'archive-categories',
                label: 'Categories',
                icon: 'box-open',
                route: '/t/archive-categories',
            },
        ],
    },
    {
        key: 'aptos-history-container',
        label: 'About Aptos',
        icon: 'hourglass-half',
        children: [
            {
                key: 'history',
                label: 'History',
                icon: 'hourglass-half',
                route: '/t/history',
            },
            {
                key: 'about',
                label: 'About',
                icon: 'info-circle',
                route: '/settings/about',
            },
        ],
    },
    {
        key: 'registered',
        label: 'Registered',
        icon: ['far', 'user'],
        route: '/t/registered',
    },
    {
        key: 'countries',
        label: 'Countries',
        icon: 'globe-asia',
        route: '/t/countries',
    },
    {
        key: 'products-container',
        label: 'Products',
        icon: 'shopping-bag',
        children: [
            {
                key: 'products',
                label: 'Products',
                icon: 'shopping-bag',
                route: '/t/products',
            },
            {
                key: 'product-areas',
                label: 'Product Areas',
                icon: 'map',
                route: '/t/product-areas',
            },
        ],
    },
    {
        key: 'intimate-container',
        label: 'Intimate',
        icon: 'shopping-bag',
        children: [
            {
                key: 'intimate-products',
                label: 'Products',
                icon: 'shopping-bag',
                route: '/t/intimate-products',
            },
            {
                key: 'intimate-product-areas',
                label: 'Product Areas',
                icon: 'map',
                route: '/t/intimate-product-areas',
            },
            {
                key: 'intimate-texts',
                label: 'Texts',
                icon: 'chalkboard-teacher',
                route: '/settings/intimate-texts',
            },
        ],
    },
    {
        key: 'procedures',
        label: 'Procedures',
        icon: 'user-md',
        route: '/t/procedures',
    },
    {
        key: 'coaches-container',
        label: 'Trainers',
        icon: 'chalkboard-teacher',
        children: [
            {
                key: 'coaches',
                label: 'Trainers',
                icon: 'chalkboard-teacher',
                route: '/t/coaches',
            },
            {
                key: 'coach-categories',
                label: 'Trainer Categories',
                icon: 'box-open',
                route: '/t/coach-categories',
            },
        ],
    },
    {
        key: 'courses-container',
        label: 'Courses',
        icon: 'table',
        children: [
            {
                key: 'courses',
                label: 'Courses',
                icon: 'table',
                route: '/t/courses',
            },
            {
                key: 'course-tracking',
                label: 'Course Tracking',
                icon: 'video',
                route: '/t/course-tracking',
            },
        ],
    },
    {
        key: 'faq',
        label: 'Faq',
        icon: 'question-circle',
        route: '/t/faq',
    },
    {
        key: 'survey',
        label: 'Survey',
        icon: 'poll-h',
        route: '/t/survey',
    },
    {
        key: 'loyalty',
        label: 'Rewards',
        icon: 'poll-h',
        children: [
            {
                key: 'loyalty-import',
                label: 'Loyalty import',
                icon: 'barcode',
                route: '/loyalty-import',
            },
            {
                key: 'loyalty-print',
                label: 'Loyalty print',
                icon: 'print',
                route: '/loyalty-print',
            },
            {
                key: 'loyalty-history',
                label: 'Loyalty History',
                icon: 'hourglass-half',
                route: '/t/loyaltyCodes',
            },
            {
                key: 'loyalty-reports',
                label: 'Loyalty Reports',
                icon: 'file-contract',
                route: '/t/loyaltyReports',
            },
        ]
    },
    {
        key: 'puzzle-container',
        label: 'Puzzle',
        icon: 'puzzle-piece',
        children: [
            {
                key: 'puzzle-codes',
                label: 'Puzzle Codes',
                icon: 'barcode',
                route: '/t/puzzle-codes',
            },
            {
                key: 'puzzle-customers',
                label: 'Puzzle Customers',
                icon: 'users',
                route: '/t/puzzle-customers',
            },
            {
                key: 'puzzle-gifts',
                label: 'Puzzle Gifts',
                icon: 'gifts',
                route: '/t/puzzle-gifts',
            },
            {
                key: 'gift-requests',
                label: 'Gift Requests',
                icon: 'envelope-open-text',
                route: '/t/gift-requests',
            },
            {
                key: 'puzzle-terms',
                label: 'Puzzle Terms',
                icon: 'file-contract',
                route: '/settings/puzzle-terms',
            },
        ],
    },
    {
        key: 'customer-resources',
        label: 'Customer Resources',
        icon: 'file-archive',
        route: '/t/customer-resources',
    },
    {
        key: 'offices',
        label: 'Offices',
        icon: 'building',
        route: '/t/offices',
    },
    {
        key: 'contact',
        label: 'Contact',
        icon: 'envelope',
        route: '/settings/contact',
    },
    {
        key: 'stat-blocks',
        label: 'Stat Blocks',
        icon: 'sliders-h',
        route: '/settings/stat-blocks',
    },
    {
        key: 'registration-text',
        label: 'Registration Text',
        icon: 'sign',
        route: '/settings/registration-text',
    },
    {
        key: 'featured-block',
        label: 'Featured Block',
        icon: 'edit',
        route: '/settings/featured-block',
    },
    {
        key: 'subscribers',
        label: 'Subscribers',
        icon: 'user-plus',
        route: '/t/subscribers',
    },
]

export const dashboardHomeConfig = {
    key: 'home',
    label: 'Dashboard',
    icon: 'home',
    route: '/',
}

export { dashboardConfig }
